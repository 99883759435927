var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var MarketPlaceRoutes;
(function (MarketPlaceRoutes) {
    MarketPlaceRoutes["MARKETPLACE_ORDERS_URL"] = "/marketplace/orders";
    MarketPlaceRoutes["MARKETPLACE_ORDERS_VIEW_URL"] = "/marketplace/orders/order-view/";
    MarketPlaceRoutes["MARKETPLACE_ORDERS_EDIT_URL"] = "/marketplace/orders/edit-purchase-order/";
    MarketPlaceRoutes["MARKETPLACE_PROMO_CODE_URL"] = "/marketplace/promotions/promo-code";
    MarketPlaceRoutes["MARKETPLACE_PROMO_CODE_EDIT_URL"] = "/marketplace/promotions/edit-promocode/";
    MarketPlaceRoutes["MARKETPLACE_PROMO_CODE_ADD_URL"] = "/marketplace/promotions/edit-promocode";
    MarketPlaceRoutes["MARKETPLACE_CAMPAIGN_URL"] = "/marketplace/promotions/campaign";
    MarketPlaceRoutes["MARKETPLACE_BANNER_URL"] = "/marketplace/promotions/banner";
    MarketPlaceRoutes["MARKETPLACE_CASH_BACK_URL"] = "/marketplace/promotions/cash-back";
    MarketPlaceRoutes["MARKETPLACE_INCIDENTS_URL"] = "/marketplace/incidents";
    MarketPlaceRoutes["MARKETPLACE_INVENTORY_URL"] = "/marketplace/inventory";
    MarketPlaceRoutes["MARKETPLACE_INVENTORY_IMPORT_URL"] = "/marketplace/inventory/import-inventory";
    MarketPlaceRoutes["MARKETPLACE_REFERRALS_OVERVIEW_URL"] = "/marketplace/referrals-overview";
    MarketPlaceRoutes["MARKETPLACE_USERS_URL"] = "/marketplace/users";
    MarketPlaceRoutes["MARKETPLACE_ROLES_URL"] = "/marketplace/roles";
    MarketPlaceRoutes["MARKETPLACE_ROLES_PERMISSIONS_URL"] = "/marketplace/roles/permissions/";
    MarketPlaceRoutes["MARKETPLACE_WAREHOUSE_DASHBOARD_URL"] = "/marketplace/warehouse-dashboard";
    MarketPlaceRoutes["MARKETPLACE_ORDER_MAP_URL"] = "/marketplace/order/order-map";
    MarketPlaceRoutes["MARKETPLACE_CUSTOMERS_URL"] = "/marketplace/customers";
    MarketPlaceRoutes["MARKETPLACE_AREA_URL"] = "/marketplace/area-view";
    MarketPlaceRoutes["MARKETPLACE_EDIT_AREA_URL"] = "/marketplace/area-view/edit-area/";
    MarketPlaceRoutes["MARKETPLACE_NEW_AREA_URL"] = "/marketplace/area-view/new-area";
    MarketPlaceRoutes["MARKETPLACE_CUSTOMERS_VIEW_URL"] = "/marketplace/customers/customer-view/";
    MarketPlaceRoutes["MARKETPLACE_CUSTOMERS_BLACKLISTED_URL"] = "/marketplace/customers/blacklisted";
    MarketPlaceRoutes["MARKETPLACE_TEAMS_URL"] = "/marketplace/teams";
    MarketPlaceRoutes["MARKETPLACE_TEAMS_RIDER_VIEW_URL"] = "/marketplace/teams/rider-view/";
    MarketPlaceRoutes["MARKETPLACE_ALERT_URL"] = "/marketplace/alert";
    MarketPlaceRoutes["MARKETPLACE_TAGS_URL"] = "/marketplace/tags";
    MarketPlaceRoutes["MARKETPLACE_MERCHANTS_URL"] = "/marketplace/merchants";
    MarketPlaceRoutes["MARKETPLACE_MERCHANTS_VIEW_URL"] = "marketplace/merchants/edit-merchant/";
    MarketPlaceRoutes["MARKETPLACE_MERCHANTS_BULK_PRODUCT_URL"] = "marketplace/merchants/bulk-import-products/";
    MarketPlaceRoutes["MARKETPLACE_MERCHANT_STORES_VIEW_URL"] = "/stores/store-details/";
    MarketPlaceRoutes["MARKETPLACE_INCIDENT_CONFIG_URL"] = "/marketplace/incident-config";
    MarketPlaceRoutes["MARKETPLACE_HOME_PAGE_ITEMS_URL"] = "/marketplace/home-pages";
    MarketPlaceRoutes["MARKETPLACE_HOME_PAGE_ITEMS_DETAIL_URL"] = "/marketplace/home-pages/home-page-items/";
    MarketPlaceRoutes["MARKETPLACE_MERCHANT_COLLECTION_URL"] = "/add-collection/";
    MarketPlaceRoutes["MARKETPLACE_MERCHANT_PRODUCT_URL"] = "/marketplace-product-view/";
    MarketPlaceRoutes["MARKETPLACE_RATING_URL"] = "/marketplace/rating";
    MarketPlaceRoutes["MARKETPLACE_APP_SETTING_URL"] = "/marketplace/app-setting";
    MarketPlaceRoutes["MARKETPLACE_CONFIGURATION_URL"] = "/marketplace/configuration";
    MarketPlaceRoutes["MARKETPLACE_CONFIGURATION_DRIVER_ICON_URL"] = "/marketplace/configuration/driver-icon";
    MarketPlaceRoutes["MARKETPLACE_REPORTS_URL"] = "/marketplace/reports";
    MarketPlaceRoutes["MARKETPLACE_CONFIGURATION_SURCHARGE_URL"] = "/marketplace/configuration/surcharge";
    // Deals & Discounts
    MarketPlaceRoutes["MARKETPLACE_DEALS_AND_DISCOUNTS"] = "/marketplace/promotions/deals-and-discounts";
    MarketPlaceRoutes["MARKETPLACE_ADD_DEALS_AND_DISCOUNTS"] = "/marketplace/promotions/deals-and-discounts/add";
})(MarketPlaceRoutes || (MarketPlaceRoutes = {}));
export var ClientExpressViewRoutes;
(function (ClientExpressViewRoutes) {
    ClientExpressViewRoutes["CLIENT_EXPRESS_VIEW_BRANCHES_URL"] = "/clientExpressView/branches";
    ClientExpressViewRoutes["CLIENT_EXPRESS_VIEW_ORDER_DETAILS_URL"] = "clientExpressView/order-details/";
})(ClientExpressViewRoutes || (ClientExpressViewRoutes = {}));
export var CourierRoutes;
(function (CourierRoutes) {
    CourierRoutes["COURIER_MAP_URL"] = "/courier/map";
    CourierRoutes["COURIER_AGENCY_URL"] = "/courier/agency-view";
    CourierRoutes["COURIER_CUSTOMERS_URL"] = "/courier/customers";
    CourierRoutes["COURIER_CUSTOMERS_ADD_URL"] = "/courier/customers/new-customer";
    CourierRoutes["COURIER_LOCATION_URL"] = "/courier/eddresses-view";
    CourierRoutes["COURIER_RETURN_URL"] = "/courier/returns";
    CourierRoutes["COURIER_ZONE_URL"] = "/courier/zone-list";
    CourierRoutes["COURIER_MERCHANT_NEW_EDDRESS_URL"] = "/courier/eddresses-view/merchant-new-eddress/";
    CourierRoutes["COURIER_CUSTOMER_VIEW_URL"] = "/courier/customers/customer-view";
    CourierRoutes["COURIER_ORDER_VIEW_URL"] = "/courier/customers/merchant-tasks-list/";
    CourierRoutes["COURIER_WORKER_URL"] = "/courier/workers";
    CourierRoutes["COURIER_WORKER_ADD_URL"] = "/courier/workers/new-worker";
    CourierRoutes["COURIER_WORKER_VIEW_URL"] = "/courier/workers/worker-view";
    CourierRoutes["COURIER_NEW_ZONE_URL"] = "/courier/workers/new-zone";
    CourierRoutes["COURIER_EDIT_ZONE_URL"] = "/courier/workers/edit-zone/";
    CourierRoutes["COURIER_INCOMING_ORDERS_URL"] = "/courier/incoming-orders";
    CourierRoutes["COURIER_NEW_ORDER_URL"] = "/courier/incoming-orders/client-express-place-order";
})(CourierRoutes || (CourierRoutes = {}));
export var InterimRoutes;
(function (InterimRoutes) {
    InterimRoutes["INTERIM_ORDERS_URL"] = "/interim-orders";
})(InterimRoutes || (InterimRoutes = {}));
export var BillingRoutes;
(function (BillingRoutes) {
    BillingRoutes["BILLING_AGENT_BASE_URL"] = "/billing/agent/";
    BillingRoutes["BILLING_AGENT_URL"] = "/billing/agent";
    BillingRoutes["BILLING_AGENT_ORDERS_URL"] = "billing/agent/orders";
    BillingRoutes["BILLING_CUSTOMER_URL"] = "/billing/customer";
    BillingRoutes["BILLING_CUSTOMER_BASE_URL"] = "/billing/customer";
    BillingRoutes["BILLING_ORDERS_URL"] = "/orders";
    BillingRoutes["BILLING_INVOICE_ORDERS_URL"] = "/invoice-orders";
    BillingRoutes["BILLING_DETAIL_URL"] = "/detail";
})(BillingRoutes || (BillingRoutes = {}));
export var DispatcherRoutes;
(function (DispatcherRoutes) {
    DispatcherRoutes["DISPATCHER_TEAMS_URL"] = "/dispatcher/teams";
})(DispatcherRoutes || (DispatcherRoutes = {}));
export var AdminSettingRoutes;
(function (AdminSettingRoutes) {
    AdminSettingRoutes["ADMIN_SETTINGS"] = "/admin-settings";
})(AdminSettingRoutes || (AdminSettingRoutes = {}));
export var ClientViewRoutes;
(function (ClientViewRoutes) {
    ClientViewRoutes["CLIENT_VIEW_DASHBOARD_URL"] = "/clientview/dashboard";
    ClientViewRoutes["CLIENT_VIEW_PROJECTS_URL"] = "/clientview/projects";
    ClientViewRoutes["CLIENT_VIEW_CUSTOMERS_URL"] = "/clientview/customers";
    ClientViewRoutes["CLIENT_VIEW_ORDERS_URL"] = "clientview/orders";
    ClientViewRoutes["CLIENT_VIEW_ORDERS_VIEW_URL"] = "clientview/orders/view";
})(ClientViewRoutes || (ClientViewRoutes = {}));
export var WareHouseViewRoutes;
(function (WareHouseViewRoutes) {
    WareHouseViewRoutes["WAREHOUSE_INCOMING_ORDERS_VIEW_URL"] = "/warehouse/purchase-orders-view";
    WareHouseViewRoutes["WAREHOUSE_ORDERS_EDIT_URL"] = "/warehouse/purchase-orders-view/edit-purchase-order/";
    WareHouseViewRoutes["WAREHOUSE_NEW_PRODUCT_URL"] = "/warehouse/purchase-orders-view/product-view/";
})(WareHouseViewRoutes || (WareHouseViewRoutes = {}));
export var LastMileRoutes;
(function (LastMileRoutes) {
    LastMileRoutes["LAST_MILE_WAREHOUSE_OVERVIEW_URL"] = "/last-mile/orders/warehouse-overview";
    LastMileRoutes["LAST_MILE_TEAMS_OVERVIEW_URL"] = "/last-mile/teams/teams-overview";
})(LastMileRoutes || (LastMileRoutes = {}));
export var AutheticationRoutes;
(function (AutheticationRoutes) {
    AutheticationRoutes["LOGIN_URL"] = "portal/login";
    AutheticationRoutes["SIGNUP_URL"] = "portal/signup/";
})(AutheticationRoutes || (AutheticationRoutes = {}));
export var PortalSettingRoutes;
(function (PortalSettingRoutes) {
    PortalSettingRoutes["PORTAL_SETTINGS"] = "/portal-settings";
})(PortalSettingRoutes || (PortalSettingRoutes = {}));
export var AppRoutesEnum = __assign({}, MarketPlaceRoutes, ClientExpressViewRoutes, InterimRoutes, BillingRoutes, DispatcherRoutes, AdminSettingRoutes, ClientViewRoutes, LastMileRoutes, AutheticationRoutes, CourierRoutes, WareHouseViewRoutes, PortalSettingRoutes);
